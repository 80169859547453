
import Vue from 'vue';
import { mapGetters } from 'vuex';

import { copyTextWithPermissions } from 'Lib/utils/copyTextWithPermissions';
import Button from 'Control/Button.vue';
import Icon from 'UI/Icon.vue';
import Checkbox from 'Control/Checkbox.vue';
import Guid from 'UI/Guid.vue';
import { deleteKey } from 'Store/v2/PlacementApiKeysManagement';
import DeleteAPIKeyRequest from 'Entities/cexAccountManagement/DeleteAPIKeyRequest';
import ApiError from 'Entities/ApiError';

interface Data {
    saved: boolean;
}
interface Methods {
    copyPublic: () => void;
    copySecret: () => void;
    onComplete: () => void;
    truncateKey: (data: string) => string;
    deleteApiKey: () => void;
    getPlacementNameByTag: (data: string) => void;
}
interface Computed {
    apiKey: {
        key: string;
        secret: string;
        status: string;
        placementTags: string[];
        isNew: boolean;
        ips: string;
        label: string;
    };
    headerText: string;
    ips: string[];
    activeAccountId: string;
}

export default Vue.extend<Data, Methods, Computed>({
    name: 'ManageApiKeys',
    components: {
        Button,
        Icon,
        Checkbox,
        Guid,
    },
    data() {
        return {
            saved: false,
        };
    },
    computed: {
        ...mapGetters({
            activeAccountId: 'Accounts/activeAccountID',
        }),
        apiKey() {
            return this.$store.state.PlacementApiKeysManagement.newKey;
        },
        headerText() {
            if (this.apiKey.isNew) {
                return `${this.apiKey.label} Exchange API-Key has been created`;
            }
            return `${this.apiKey.label} Exchange API-Key`;
        },
        ips() {
            return this.apiKey.ips.split(', ');
        },
    },
    methods: {
        getPlacementNameByTag(tag) {
            const map: Map<string, string> = this.$store.state.Placements.placementNamesToPlacementTags;
            return Array.from(map.keys())
                .find((key) => map.get(key) === tag) ?? '';
        },
        async deleteApiKey() {
            if (!this.apiKey.key || !this.apiKey.placementTags || this.apiKey.status !== 'active') {
                await this.$store.dispatch('Notificator/showErrorNotification', 'You cannot delete this key');
                return;
            }

            try {
                await this.$store.dispatch(deleteKey({
                    requestPayload: new DeleteAPIKeyRequest({
                        key: this.apiKey.key,
                        placementTag: this.apiKey.placementTags[0],
                    }),
                    accountId: this.activeAccountId,
                }));
            } catch (error) {
                if (error instanceof ApiError) {
                    await this.$store.dispatch('Notificator/showErrorNotification', error.data ? error.data.message : 'Something Went Wrong');
                }
            }
        },
        async copyPublic() {
            await copyTextWithPermissions(this.apiKey.key, this.$store.dispatch, 'API key');
        },
        async copySecret() {
            await copyTextWithPermissions(this.apiKey.secret, this.$store.dispatch, 'Secret');
        },
        onComplete() {
            this.$router.replace('/accounts/placement-api-keys-management');
        },
        truncateKey(key: string) {
            if (key.length < 9) {
                return key;
            }
            return `${key.slice(0, 4)}...${key.slice(-4)}`;
        },
    },
    mounted() {
        if (!this.apiKey.key) {
            this.onComplete();
        }
    },
});
